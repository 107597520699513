import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "D:/react/raul/raulPortfolio/src/components/layoutmdx.js";
import "./grid.css";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Grid`}</h1>
    <h2>{`Conceptos`}</h2>
    <ul>
      <li parentName="ul">{`container: define la cuadrícula.`}</li>
      <li parentName="ul">{`item: cada elemento hijo.`}</li>
      <li parentName="ul">{`grid cell: cada cuadro o unidad mínima de la cuadrícula.`}</li>
      <li parentName="ul">{`grid area: conjunto de celdas.`}</li>
      <li parentName="ul">{`grid track: conjunto total horizontal o vertical de celdas.`}</li>
      <li parentName="ul">{`grid line: línea separadora entre celdas.`}</li>
    </ul>
    <h2>{`Asignar número de filas y columnas`}</h2>
    <ul>
      <li parentName="ul">{`grid-template-columns: número de columnas.`}</li>
      <li parentName="ul">{`grid-template-rows: número de filas.`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.grid-container-33{
  display:grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto auto;
}
.grid-item{
  border: solid black 1px;
  color: orange;
  background-color:darkslateblue;
}

<div class="grid-container-33">
<div class="grid-item">auto x auto</div>
<div class="grid-item">auto x auto</div>
<div class="grid-item">auto x auto</div>
<div class="grid-item">auto x auto</div>
<div class="grid-item">auto x auto</div>
<div class="grid-item">auto x auto</div>
<div class="grid-item">auto x auto</div>
<div class="grid-item">auto x auto</div>
<div class="grid-item">auto x auto</div>
</div>
`}</code></pre>
    <div className="grid-container-33">
  <div className="grid-item">auto x auto</div>
  <div className="grid-item">auto x auto</div>
  <div className="grid-item">auto x auto</div>
  <div className="grid-item">auto x auto</div>
  <div className="grid-item">auto x auto</div>
  <div className="grid-item">auto x auto</div>
  <div className="grid-item">auto x auto</div>
  <div className="grid-item">auto x auto</div>
  <div className="grid-item">auto x auto</div>
    </div>
    <ul>
      <li parentName="ul">{`fr: asigna según el espacio libre.`}</li>
    </ul>
    <div className="grid-container-33-fr">
  <div className="grid-item">150px x 150px</div>
  <div className="grid-item">1fr x 150px</div>
  <div className="grid-item">150px x 150px</div>
  <div className="grid-item">150px x 150px</div>
  <div className="grid-item">1fr x 150px</div>
  <div className="grid-item">150px x 150px</div>
  <div className="grid-item">150px x 150px</div>
  <div className="grid-item">1fr x 150px</div>
  <div className="grid-item">150px x 150px</div>
    </div>
    <h2>{`gap`}</h2>
    <p>{`gap es un shorthand de row-gap y column-gap.`}</p>
    <ul>
      <li parentName="ul">{`row-gap: espacio entre filas.`}</li>
      <li parentName="ul">{`column-gap: espacio entre columnas.`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="grid-container-33" style="row-gap: 5px"></div>
`}</code></pre>
    <div className="grid-container-33" style={{
      "rowGap": "5px"
    }}>
  <div className="grid-item">auto x auto</div>
  <div className="grid-item">auto x auto</div>
  <div className="grid-item">auto x auto</div>
  <div className="grid-item">auto x auto</div>
  <div className="grid-item">auto x auto</div>
  <div className="grid-item">auto x auto</div>
  <div className="grid-item">auto x auto</div>
  <div className="grid-item">auto x auto</div>
  <div className="grid-item">auto x auto</div>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="grid-container-33" style="column-gap: 5px"></div>
`}</code></pre>
    <div className="grid-container-33" style={{
      "columnGap": "5px"
    }}>
  <div className="grid-item">auto x auto</div>
  <div className="grid-item">auto x auto</div>
  <div className="grid-item">auto x auto</div>
  <div className="grid-item">auto x auto</div>
  <div className="grid-item">auto x auto</div>
  <div className="grid-item">auto x auto</div>
  <div className="grid-item">auto x auto</div>
  <div className="grid-item">auto x auto</div>
  <div className="grid-item">auto x auto</div>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="grid-container-33" style="gap: 5px 5px"></div>
`}</code></pre>
    <div className="grid-container-33" style={{
      "gap": "5px 5px"
    }}>
  <div className="grid-item">auto x auto</div>
  <div className="grid-item">auto x auto</div>
  <div className="grid-item">auto x auto</div>
  <div className="grid-item">auto x auto</div>
  <div className="grid-item">auto x auto</div>
  <div className="grid-item">auto x auto</div>
  <div className="grid-item">auto x auto</div>
  <div className="grid-item">auto x auto</div>
  <div className="grid-item">auto x auto</div>
    </div>
    <h2>{`grid-row y grid-column`}</h2>
    <ul>
      <li parentName="ul">{`grid-row: asigna al item el número de filas que ocupará.`}</li>
      <li parentName="ul">{`grid-column: asigna al item el número de columnas que ocupará.`}</li>
    </ul>
    <p>{`con span más un número indicamos en que fila o columna queremos acabar.
1 / 3 (des de la línea 1 hasta la 3) = 1 / span 2 (des de la fila/columna 1 más 2)`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="grid-container-33">
  <div class="grid-item" style="grid-column: 1/last; background-color:tomato">
    grid-column: 1/last
  </div>
  <div
    class="grid-item"
    style="grid-column: 1/3; grid-row:2/4; background-color:crimson"
  >
    grid-column: 1/3; grid-row:2/4
  </div>
  <div
    class="grid-item"
    style="grid-column: 3/ span 2; background-color: orange;"
  >
    grid-column: 3/ span 2;
  </div>
  <div class="grid-item">auto x auto</div>
  <div class="grid-item">auto x auto</div>
  <div class="grid-item">auto x auto</div>
  <div class="grid-item">auto x auto</div>
  <div class="grid-item">auto x auto</div>
  <div class="grid-item">auto x auto</div>
</div>
`}</code></pre>
    <div className="grid-container-33">
  <div className="grid-item" style={{
        "gridColumn": "1/last",
        "backgroundColor": "tomato"
      }}>
    grid-column: 1/last
  </div>
  <div className="grid-item" style={{
        "gridColumn": "1/3",
        "gridRow": "2/4",
        "backgroundColor": "crimson"
      }}>
    grid-column: 1/3; grid-row:2/4
  </div>
  <div className="grid-item" style={{
        "gridColumn": "3/ span 2",
        "backgroundColor": "orange"
      }}>
    grid-column: 3/ span 2;
  </div>
  <div className="grid-item">auto x auto</div>
  <div className="grid-item">auto x auto</div>
  <div className="grid-item">auto x auto</div>
  <div className="grid-item">auto x auto</div>
  <div className="grid-item">auto x auto</div>
  <div className="grid-item">auto x auto</div>
    </div>
    <h2>{`repeat(cantidad, medida)`}</h2>
    <p>{`Se utiliza como medida para repetir tamaños`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.grid-container-33-repeat {
  display: grid;
  grid-template-columns: repeat(3, 100px);
  grid-template-rows: repeat(3, 100px);
}
`}</code></pre>
    <div className="grid-container-33-repeat">
  <div className="grid-item">100 x 100</div>
  <div className="grid-item">100 x 100</div>
  <div className="grid-item">100 x 100</div>
  <div className="grid-item">100 x 100</div>
  <div className="grid-item">100 x 100</div>
  <div className="grid-item">100 x 100</div>
  <div className="grid-item">100 x 100</div>
  <div className="grid-item">100 x 100</div>
  <div className="grid-item">100 x 100</div>
    </div>
    <h2>{`auto-fill y auto-fit`}</h2>
    <p>{`Estas dos propiedades generan tantas columnas como quepan en el contenedor, la diferencia es que auto-fill si tiene espacio libre pero no suficiente para otra columna no añadirá nada, en cambio, auto-fit si que ocupará ese espacio.`}</p>
    <h3>{`auto-fill`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div style="display:grid; grid-template-columns: repeat(auto-fill, 100px);">
  <div class="grid-item" style="background-color: gold">100px</div>
  <div class="grid-item" style="background-color: orange">100px</div>
  <div class="grid-item" style="background-color: tomato">100px</div>
  <div class="grid-item" style="background-color: crimson">100px</div>
  <div class="grid-item" style="background-color: lightcoral">100px</div>
  <div class="grid-item" style="background-color: plum">100px</div>
  <div class="grid-item" style="background-color: skyblue">100px</div>
  <div class="grid-item" style="background-color: lightgreen">100px</div>
</div>
`}</code></pre>
    <div style={{
      "display": "grid",
      "gridTemplateColumns": "repeat(auto-fill, 100px)"
    }}>
      <div className="grid-item" style={{
        "backgroundColor": "gold"
      }}>100px</div>
      <div className="grid-item" style={{
        "backgroundColor": "orange"
      }}>100px</div>
      <div className="grid-item" style={{
        "backgroundColor": "tomato"
      }}>100px</div>
      <div className="grid-item" style={{
        "backgroundColor": "crimson"
      }}>100px</div>
      <div className="grid-item" style={{
        "backgroundColor": "lightcoral"
      }}>100px</div>
      <div className="grid-item" style={{
        "backgroundColor": "plum"
      }}>100px</div>
      <div className="grid-item" style={{
        "backgroundColor": "skyblue"
      }}>100px</div>
      <div className="grid-item" style={{
        "backgroundColor": "lightgreen"
      }}>100px</div>
    </div>
    <h3>{`auto-fit`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div style="display:grid; grid-template-columns: repeat(auto-fit, 100px);">
  <div class="grid-item" style="background-color: gold">100px</div>
  <div class="grid-item" style="background-color: orange">100px</div>
  <div class="grid-item" style="background-color: tomato">100px</div>
  <div class="grid-item" style="background-color: crimson">100px</div>
  <div class="grid-item" style="background-color: lightcoral">100px</div>
  <div class="grid-item" style="background-color: plum">100px</div>
  <div class="grid-item" style="background-color: skyblue">100px</div>
  <div class="grid-item" style="background-color: lightgreen">100px</div>
</div>
`}</code></pre>
    <div style={{
      "display": "grid",
      "gridTemplateColumns": "repeat(auto-fit, 100px)"
    }}>
  <div className="grid-item" style={{
        "backgroundColor": "gold"
      }}>
    100px
  </div>
  <div className="grid-item" style={{
        "backgroundColor": "orange"
      }}>
    100px
  </div>
  <div className="grid-item" style={{
        "backgroundColor": "tomato"
      }}>
    100px
  </div>
  <div className="grid-item" style={{
        "backgroundColor": "crimson"
      }}>
    100px
  </div>
  <div className="grid-item" style={{
        "backgroundColor": "lightcoral"
      }}>
    100px
  </div>
  <div className="grid-item" style={{
        "backgroundColor": "plum"
      }}>
    100px
  </div>
  <div className="grid-item" style={{
        "backgroundColor": "skyblue"
      }}>
    100px
  </div>
  <div className="grid-item" style={{
        "backgroundColor": "lightgreen"
      }}>
    100px
  </div>
    </div>
    <p>{`Como puedes observar no se aprecian diferencias, para poder ver diferencias debemos añadir la propiedad minmax()`}</p>
    <h3>{`auto-fit, minmax(75px, 1fr)`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div
  style="display:grid; grid-template-columns: repeat(auto-fit, minmax(75px, 1fr));"
></div>
`}</code></pre>
    <div style={{
      "display": "grid",
      "gridTemplateColumns": "repeat(auto-fit, minmax(75px, 1fr))"
    }}>
      <div className="grid-item" style={{
        "backgroundColor": "gold"
      }}>75px</div>
      <div className="grid-item" style={{
        "backgroundColor": "orange"
      }}>75px</div>
      <div className="grid-item" style={{
        "backgroundColor": "tomato"
      }}>75px</div>
      <div className="grid-item" style={{
        "backgroundColor": "crimson"
      }}>75px</div>
      <div className="grid-item" style={{
        "backgroundColor": "lightcoral"
      }}>75px</div>
      <div className="grid-item" style={{
        "backgroundColor": "plum"
      }}>75px</div>
      <div className="grid-item" style={{
        "backgroundColor": "skyblue"
      }}>75px</div>
      <div className="grid-item" style={{
        "backgroundColor": "lightgreen"
      }}>75px</div>
    </div>
    <h3>{`auto-fill, minmax(75px, 1fr)`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div
  style="display:grid; grid-template-columns: repeat(auto-fill, minmax(75px, 1fr));"
></div>
`}</code></pre>
    <div style={{
      "display": "grid",
      "gridTemplateColumns": "repeat(auto-fill, minmax(75px, 1fr))"
    }}>
  <div className="grid-item" style={{
        "backgroundColor": "gold"
      }}>
    75px
  </div>
  <div className="grid-item" style={{
        "backgroundColor": "orange"
      }}>
    75px
  </div>
  <div className="grid-item" style={{
        "backgroundColor": "tomato"
      }}>
    75px
  </div>
  <div className="grid-item" style={{
        "backgroundColor": "crimson"
      }}>
    75px
  </div>
  <div className="grid-item" style={{
        "backgroundColor": "lightcoral"
      }}>
    75px
  </div>
  <div className="grid-item" style={{
        "backgroundColor": "plum"
      }}>
    75px
  </div>
  <div className="grid-item" style={{
        "backgroundColor": "skyblue"
      }}>
    75px
  </div>
  <div className="grid-item" style={{
        "backgroundColor": "lightgreen"
      }}>
    75px
  </div>
    </div>
    <h2>{`grid-template-areas`}</h2>
    <p>{`Es un método para definir las columnas y filas, consiste en asignar por nombres cada parte. En primer lugar asignamos el formato del contenedor:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.grid-container-areas {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 100px);
  grid-template-areas:
    "header header header header"
    "sidebar-left main main sidebar-right"
    "footer footer footer .";
  /* El "." asigna espacio en blanco */
}
`}</code></pre>
    <p>{`Y de la misma forma que antes debemos asignar con grid-area un item a cada zona:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.header {
  grid-area: header;
  background-color: lightcoral;
}
.sidebar-left {
  grid-area: sidebar-left;
  background-color: plum;
}
.sidebar-right {
  grid-area: sidebar-right;
  background-color: lightgreen;
}
.main {
  grid-area: main;
  background-color: skyblue;
}
.footer {
  grid-area: footer;
  background-color: crimson;
}
`}</code></pre>
    <div className="grid-container-areas">
  <div className="header">header</div>
  <div className="footer">footer</div>
  <div className="sidebar-left">sidebar-left</div>
  <div className="sidebar-right">sidebar-right</div>
  <div className="main">main</div>
    </div>
    <h2>{`grid-template`}</h2>
    <p>{`Es un shorthand de las propiedades grid-template-columns, grid-template-rows y grid-template-areas.
Tendremos que asignar el espacio con el nombre de cada uno y después de las comillas añadimos el tamaño de esa fila, por último añadiremos después de la barra "/" el tamaño de cada columna. Igual que antes debemos de añadir los items dentro del contenedor y asignar el espacio.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.grid-container-template {
  display: grid;
  grid-template:
    "header header header header" 100px
    "sidebar-left main main sidebar-right" 100px
    "footer footer footer ." 100px
    / 1fr 1fr 1fr 1fr;
}
`}</code></pre>
    <div className="grid-container-template">
  <div className="header">header</div>
  <div className="footer">footer</div>
  <div className="sidebar-left">sidebar-left</div>
  <div className="sidebar-right">sidebar-right</div>
  <div className="main">main</div>
    </div>
    <h2>{`align-items`}</h2>
    <h3>{`stretch (default) | center | start |end`}</h3>
    <p>{`Alinea los items en el eje vertical.`}</p>
    <h4>{`stretch`}</h4>
    <div className="grid-container-align" style={{
      "alignItems": "stretch"
    }}>
  <div className="grid-item" style={{
        "backgroundColor": "gold"
      }}>
    100 x 75 px
  </div>
  <div className="grid-item" style={{
        "backgroundColor": "orange"
      }}>
    100 x 75 px
  </div>
  <div className="grid-item" style={{
        "backgroundColor": "tomato"
      }}>
    100 x 75 px
  </div>
  <div className="grid-item" style={{
        "backgroundColor": "crimson"
      }}>
    100 x 75 px
  </div>
    </div>
    <h4>{`center`}</h4>
    <div className="grid-container-align" style={{
      "alignItems": "center"
    }}>
  <div className="grid-item" style={{
        "backgroundColor": "gold"
      }}>
    100 x 75 px
  </div>
  <div className="grid-item" style={{
        "backgroundColor": "orange"
      }}>
    100 x 75 px
  </div>
  <div className="grid-item" style={{
        "backgroundColor": "tomato"
      }}>
    100 x 75 px
  </div>
  <div className="grid-item" style={{
        "backgroundColor": "crimson"
      }}>
    100 x 75 px
  </div>
    </div>
    <h4>{`start`}</h4>
    <div className="grid-container-align" style={{
      "alignItems": "start"
    }}>
  <div className="grid-item" style={{
        "backgroundColor": "gold"
      }}>
    100 x 75 px
  </div>
  <div className="grid-item" style={{
        "backgroundColor": "orange"
      }}>
    100 x 75 px
  </div>
  <div className="grid-item" style={{
        "backgroundColor": "tomato"
      }}>
    100 x 75 px
  </div>
  <div className="grid-item" style={{
        "backgroundColor": "crimson"
      }}>
    100 x 75 px
  </div>
    </div>
    <h4>{`end`}</h4>
    <div className="grid-container-align" style={{
      "alignItems": "end"
    }}>
  <div className="grid-item" style={{
        "backgroundColor": "gold"
      }}>
    100 x 75 px
  </div>
  <div className="grid-item" style={{
        "backgroundColor": "orange"
      }}>
    100 x 75 px
  </div>
  <div className="grid-item" style={{
        "backgroundColor": "tomato"
      }}>
    100 x 75 px
  </div>
  <div className="grid-item" style={{
        "backgroundColor": "crimson"
      }}>
    100 x 75 px
  </div>
    </div>
    <h2>{`justify-items`}</h2>
    <h3>{`stretch (default) | center | start |end`}</h3>
    <h4>{`stretch`}</h4>
    <div className="grid-container-align" style={{
      "justifyItems": "stretch"
    }}>
  <div className="grid-item" style={{
        "backgroundColor": "gold"
      }}>
    100 x 75 px
  </div>
  <div className="grid-item" style={{
        "backgroundColor": "orange"
      }}>
    100 x 75 px
  </div>
  <div className="grid-item" style={{
        "backgroundColor": "tomato"
      }}>
    100 x 75 px
  </div>
  <div className="grid-item" style={{
        "backgroundColor": "crimson"
      }}>
    100 x 75 px
  </div>
    </div>
    <h4>{`center`}</h4>
    <div className="grid-container-align" style={{
      "justifyItems": "center"
    }}>
  <div className="grid-item" style={{
        "backgroundColor": "gold"
      }}>
    100 x 75 px
  </div>
  <div className="grid-item" style={{
        "backgroundColor": "orange"
      }}>
    100 x 75 px
  </div>
  <div className="grid-item" style={{
        "backgroundColor": "tomato"
      }}>
    100 x 75 px
  </div>
  <div className="grid-item" style={{
        "backgroundColor": "crimson"
      }}>
    100 x 75 px
  </div>
    </div>
    <h4>{`start`}</h4>
    <div className="grid-container-align" style={{
      "justifyItems": "start"
    }}>
  <div className="grid-item" style={{
        "backgroundColor": "gold"
      }}>
    100 x 75 px
  </div>
  <div className="grid-item" style={{
        "backgroundColor": "orange"
      }}>
    100 x 75 px
  </div>
  <div className="grid-item" style={{
        "backgroundColor": "tomato"
      }}>
    100 x 75 px
  </div>
  <div className="grid-item" style={{
        "backgroundColor": "crimson"
      }}>
    100 x 75 px
  </div>
    </div>
    <h4>{`end`}</h4>
    <div className="grid-container-align" style={{
      "justifyItems": "end"
    }}>
  <div className="grid-item" style={{
        "backgroundColor": "gold"
      }}>
    100 x 75 px
  </div>
  <div className="grid-item" style={{
        "backgroundColor": "orange"
      }}>
    100 x 75 px
  </div>
  <div className="grid-item" style={{
        "backgroundColor": "tomato"
      }}>
    100 x 75 px
  </div>
  <div className="grid-item" style={{
        "backgroundColor": "crimson"
      }}>
    100 x 75 px
  </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      